import "@/styles/globals.scss";
import "@/styles/reset.css";
import { useEffect } from "react";
import { WKGatherer } from "@wk/wk-gatherer";
import type { AppProps } from "next/app";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    WKGatherer({
      config: { env: process.env.NEXT_PUBLIC_STAGE || "develop" },
      data: { ak: "landing" },
    });
    console.log(
      "env-->",
      process.env.NEXT_PUBLIC_VERCEL_ENV,
      process.env.VERCEL_ENV
    );
    // 加载eruda控制台 （ios会缓存提前页面资源， require并不会加载 换一种方式才加载eruda）
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" ||
      /eruda=true/.test(window?.location?.href)
    ) {
      (function () {
        const script = document.createElement("script");
        script.src = "//cdn.jsdelivr.net/npm/eruda";
        document.body.appendChild(script);
        script.onload = function () {
          window.eruda?.init();
        };
      })();
    }
  }, []);
  return <Component {...pageProps} />;
}
